<template>
  <div :style="cssVars">
    <div class="headerBorder"></div>
    <div v-if="!trackContent">We can't seem to find that track</div>
    <div v-if="trackContent.length">
      <div id="trackHeader" class="verticalSpacer">
        <div class="bodySpacer">
            <div class="svgIcon" v-html="findTrackIcon(localTrack.iconSlug)"></div>
          <div>
            <h4>Learning Track</h4>
            <h2>{{localTrack.title}}</h2>
            <h5>{{localTrack.units.length}} Lessons</h5>
            <div class="trackOverview">
              <p>{{ localTrack.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bodySpacer verticalSpacer">
        <div id="unitList">
          <div ref="unit" class="unit" v-for="unit in sortedUnits" v-bind:key="unit.unitId" v-bind:class="[isActive(unit.unitId, unit.title), isUpdated(unit)]">
            <div class="unitTop" @click="expand($event, unit.unitId, unit.title)">
              <div class="unitLeft">
                <h5>
                  <svg v-if="unit.type === 'Conversation'" viewBox="0 0 100 100">
                    <path d="M81.64,90.06c-.53,0-1.07-.11-1.57-.32l-28.35-12.15c-.57,.02-1.13,.03-1.71,.03C12.9,77.62,5.11,59.22,5.11,43.78S12.9,9.94,50,9.94s44.89,18.4,44.89,33.84c0,11.35-4.17,20.01-12.42,25.78l3.09,15.73c.29,1.46-.26,2.95-1.41,3.88-.72,.59-1.62,.89-2.52,.89Zm-29.16-20.49c.54,0,1.08,.11,1.58,.32l22.2,9.51-2.15-10.97c-.33-1.67,.44-3.37,1.92-4.23,7.32-4.25,10.88-10.93,10.88-20.42s-3.3-15.74-10.1-20c-6.18-3.88-15.2-5.84-26.79-5.84s-20.61,1.96-26.79,5.84c-6.8,4.26-10.1,10.8-10.1,20s3.3,15.74,10.1,20c6.19,3.88,15.2,5.84,26.79,5.84,.6,0,1.18-.02,1.77-.03l.6-.02s.07,0,.1,0Z" />
                  </svg>
                  <svg v-if="unit.type === 'Video'" viewBox="0 0 100 100">
                    <path d="M13.81,96.6c-.81,0-1.62-.21-2.35-.63-1.45-.84-2.35-2.39-2.35-4.07V8.97c0-1.68,.89-3.23,2.35-4.07,1.45-.83,3.25-.83,4.7,0L87.99,46.37c1.45,.84,2.35,2.39,2.35,4.07s-.89,3.23-2.35,4.07L16.16,95.97c-.72,.42-1.54,.63-2.35,.63ZM18.51,17.11V83.77l57.73-33.33L18.51,17.11Z" />
                  </svg>
                  <svg v-if="unit.type === 'Activity'" viewBox="0 0 100 100">
                    <path d="M38.53,73.82L4.16,39.45c-.98-.98-.98-2.57,0-3.55,.98-.98,2.57-.98,3.55,0l32.58,32.58,27.34-27.51L35.14,8.47c-.98-.98-.98-2.57,0-3.55,.98-.98,2.57-.98,3.55,0l34.26,34.26c.31,.44,.46,.58,.59,.92l19.85,50.57c.36,.93,.15,1.98-.56,2.69-.7,.7-1.75,.93-2.68,.57l-50.74-19.55c-.33-.13-.63-.32-.87-.57Zm31.72-28.35l-25.46,25.61,29.77,11.47,7.34-7.39-11.65-29.69Zm13.66,34.8l-4.22,4.26,6.95,2.67-2.72-6.93ZM38.73,43.84L19.25,24.36c-.98-.98-.98-2.57,0-3.55,.98-.98,2.57-.98,3.55,0l19.48,19.48c.98,.98,.98,2.57,0,3.55-.98,.98-2.57,.98-3.55,0Z" />
                    <path d="M91.05,95.1c-.42,0-.85-.08-1.26-.24l-50.74-19.55c-.46-.18-.87-.44-1.22-.79L3.46,40.16c-.66-.66-1.03-1.54-1.03-2.48s.37-1.82,1.03-2.48c1.33-1.33,3.64-1.33,4.97,0l31.87,31.87,25.93-26.09L34.43,9.18c-.66-.66-1.03-1.55-1.03-2.48s.37-1.82,1.03-2.48c1.33-1.33,3.64-1.33,4.97,0l34.38,34.4c.08,.12,.16,.22,.22,.3,.18,.24,.35,.47,.49,.84l19.84,50.55c.51,1.29,.2,2.77-.78,3.76-.67,.68-1.57,1.04-2.49,1.04Zm-51.82-21.99c.15,.15,.33,.26,.52,.34l50.75,19.55c.56,.22,1.19,.08,1.61-.34,.42-.43,.55-1.06,.33-1.62l-19.85-50.57c-.04-.11-.07-.16-.22-.36-.06-.08-.13-.17-.2-.28L37.99,5.63c-.57-.57-1.56-.57-2.14,0-.29,.29-.44,.67-.44,1.07s.16,.78,.44,1.07l33.2,33.2-28.75,28.93L7.01,36.61c-.57-.57-1.56-.57-2.14,0-.29,.29-.44,.67-.44,1.07s.16,.78,.44,1.07l34.37,34.37Zm49.15,15.83l-10.5-4.04,6.38-6.44,4.12,10.47Zm-6.92-4.81l3.4,1.31-1.33-3.39-2.06,2.08Zm-6.66-.42l-31.81-12.26,27.62-27.79,12.45,31.72-8.26,8.32Zm-28.23-13.02l27.73,10.68,6.42-6.47-10.85-27.66-23.3,23.44Zm-6.07-25.12c-.9,0-1.8-.34-2.48-1.03L18.55,25.07c-.66-.66-1.03-1.54-1.03-2.48s.37-1.82,1.03-2.48c1.33-1.33,3.64-1.33,4.97,0l19.48,19.48c.66,.66,1.03,1.54,1.03,2.48s-.37,1.82-1.03,2.48c-.68,.68-1.58,1.03-2.48,1.03Zm-1.07-2.44c.59,.59,1.55,.59,2.14,0,.29-.29,.44-.67,.44-1.07s-.16-.78-.44-1.07L22.1,21.52c-.57-.57-1.56-.57-2.14,0-.29,.29-.44,.67-.44,1.07s.16,.78,.44,1.07l19.48,19.48Z" />
                  </svg>
                  <svg v-if="unit.type === 'Resource' || unit.type === 'Resources'" viewBox="0 0 100 100">
                    <path d="M51.12,64.47H27.93c-13.76,0-24.95-11.19-24.95-24.95S14.17,14.58,27.93,14.58h23.19c13.76,0,24.95,11.19,24.95,24.95s-11.19,24.95-24.95,24.95ZM27.93,22.58c-9.35,0-16.95,7.6-16.95,16.95s7.6,16.95,16.95,16.95h23.19c9.35,0,16.95-7.6,16.95-16.95s-7.6-16.95-16.95-16.95H27.93Z" />
                    <path d="M72.07,85.42h-23.19c-13.76,0-24.95-11.19-24.95-24.95s11.19-24.95,24.95-24.95h23.19c13.76,0,24.95,11.19,24.95,24.95s-11.19,24.95-24.95,24.95Zm-23.19-41.9c-9.35,0-16.95,7.6-16.95,16.95s7.6,16.95,16.95,16.95h23.19c9.35,0,16.95-7.6,16.95-16.95s-7.6-16.95-16.95-16.95h-23.19Z" />
                  </svg>
                  {{unit.type}}
                </h5>
                <h3>{{unit.title}}</h3>
              </div>
              <div class="unitRight">
                <div class="check">
                  <svg width="40px" height="30px" viewBox="0 0 40 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g class="vector" transform="translate(-1269.000000, -2114.000000)" fill="#FFFFFF">
                              <g transform="translate(1173.000000, 2035.000000)">
                                  <g transform="translate(72.000000, 50.000000)">
                                      <path d="M57.9686755,30.0306863 L37.5138059,50.4831987 L30.0234565,42.9946262 C28.6485211,41.6203778 26.4120381,41.6203778 25.0371026,42.9946262 C23.6542991,44.3767387 23.6542991,46.6121041 25.0371026,47.9863525 L35.0176784,57.9698052 C35.7080967,58.6539804 36.6070173,59 37.5138059,59 C38.4127265,59 39.3195151,58.6539804 40.0119004,57.9698052 L62.9628974,35.0224126 C64.3457009,33.6403001 64.3457009,31.4127988 62.9628974,30.0306863 C61.5879619,28.6564379 59.3514789,28.6564379 57.9686755,30.0306863 Z" id="checkmark"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>
              </div>
            </div>
            <!-- <div class="unitSub" v-show-slide="calcExpand(unit.unitId)"> -->
            <div class="unitSub">
              <div class="unitLeft">
                <div v-html="unit.content" class="unitDescription"></div>
                <div class="actions">
                  <div v-if="unit.attachment" class="attachment" @click.prevent="downloadAttachment(unit.unitId, unit.attachment, unit.title)">Download Resource</div>
                  <a v-if="unit.type != 'Video' && isActive(unit.unitId) != 'active'" class="completeUnit" href="#" @click.prevent="setUnitCompletion(unit.unitId, unit.title)">Complete Unit</a>
                </div>
                <SSSImage v-if="unit.embedImage" :imagePath="`learningUnits/${unit.unitId}/${unit.embedImage}`"></SSSImage>
                <div v-if="(unit.link && unit.type == 'Video')" class='embed-container'>
                  <iframe v-bind:data-unitId="unit.unitId" v-bind:data-unitTitle="unit.title" v-bind:data-unitlink="unit.link" v-bind:src="'https://player.vimeo.com/video/' + unit.link + '?color=00AEF3&title=0&byline=0&portrait=0'" frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                </div>
              </div>

              <!-- <div v-if="unit.type != 'Video'" class="unitStatus" v-bind:class="isActive(unit.unitId)">
                <strong v-if="">Have you completed this activity?</strong>
                <strong v-else>Great work! You've completed this activity</strong>
                <button @click="setUnitCompletion(unit.unitId, unit.title)">
                  <span>Complete</span>
                </button>
              </div> -->
            </div>
          </div>
        </div>

        <div class="nextUpWrap">
          <div class="nextUp" v-if="localTrack.nextUp && localTrack.nextUp != ''" @click="gotToTrack(FindNextUp.slug)">
            <div class="leftSide">
              <span>What's Next?</span>
            </div>
            <div class="rightSide">
              <div class="svgIcon" v-html="findTrackIcon(FindNextUp.iconSlug)"></div>
              <div class="nextTitle">
                {{ FindNextUp.title }}
              </div>
            </div>
            <div class="nextArrow">
              <span><i class="fa-solid fa-arrow-right"></i></span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import SSSImage from '@/components/SSSImage'
import AmplifyStore from '../store/store'
import Vimeo from '@vimeo/player'
import { Storage, Logger } from 'aws-amplify';
import { usePlausible } from 'v-plausible/vue'
import { defineComponent } from 'vue';
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

//const { trackEvent, trackPageview } = usePlausible()

export default defineComponent({
  name: 'Track',
  props: ['slug'],
  components: { SSSImage},
  data () {
    return {
      trackContent: false,
      localTrack: false,
      expandedUnit: '',
      players: []
    }
  },
  created () {
    AmplifyStore.dispatch('private/getUserProgress', this.user.attributes.sub)
    this.initTrack()
  },
  unmounted () {
    this.trackContent = false
    this.localTrack = false
    this.players = false
  },
  mounted: function() {
    this.recordTrackView()
    if(!this.allIcons) AmplifyStore.dispatch('admin/getAllIcons')
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
  },
  methods: {
    initTrack: function() {
      this.trackContent = false
      this.localTrack = false
      let that = this
      this.getTrack()
        .then(res => {
          if(res.Item) {
            this.localTrack = res.Item
            return this.getTrackContents(res.Item.units)
          }
        })
        .then(res => {
          if(res) this.trackContent = res.Responses[`${this.ENV.tablePrefix}LearningUnits`]
        })
        .then(() => {
          let frames = document.querySelectorAll('iframe')
          if(frames) {
            // convert the nodelist to array
            frames = [...frames]
            this.players = frames.map( f => {
              let player = new Vimeo(f)
              let id = f.dataset.unitid
              let title = f.dataset.unittitle
              let link = f.dataset.unitlink
              let completedIndex = this.userProgress.activeUnits.indexOf(id)
              player.on('ended', function(data) {
                if ( completedIndex == -1 ){
                  // that.setUnitCompletion(id)
                }
                that.trackVideoEvent("220", title)
              });

              player.on('play', function(data) {
                if( completedIndex != -1 && data.percent < 0.1){
                  that.trackVideoEvent("210", title)
                  that.setUnitCompletion(id) // set unit complete when video starts
                } else {
                  that.trackVideoEvent("200", title)
                }
              });
              player.on('pause', function(data) {
                let percentComplete = Math.round((data.seconds / data.duration) * 100);
                if (data.percent > 0.8 && completedIndex == -1){
                    // that.setUnitCompletion(id)
                    that.trackVideoEvent("220", title)
                } else {
                  // that.trackVideoEvent("Pause Video", link, title)
                }
              });
              return {
                id: f.dataset.unitid,
                player: player
              }
            })
          }
        })
        .catch( err => {
          console.log(err)
        })
    },
    pauseAllVideos: function(){
      this.players.map(p => {
        p.player.pause();
      })
    },
    expand: function(event, id, title){
      this.$refs.unit.map( u => u.classList.remove('open') )
      // this.pauseAllVideos()
      event.target.parentNode.classList.add('open')
      this.expandedUnit = id
    },
    downloadAttachment: function(id, file, title) {
      Storage
        .get(`learningUnits/${id}/${file}`, {download: true})
        .then(res => {
          const newBlob = new Blob([res.Body], { "Content-Type": res.ContentType} )
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob, file);
              return;
          }
          const data = window.URL.createObjectURL(newBlob);
          let link = document.createElement('a');
          link.href = data;
          link.download = `${file}`;
          link.dispatchEvent(new MouseEvent(`click`, {bubbles: true, cancelable: true, view: window}));
          // link.click();

          setTimeout(function() {
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100)
        })
        .catch(err => console.log(err));
        this.setUnitCompletion(id)
        if (this.user && this.user.attributes['custom:role']!='admin') this.recordEvent('300', file, 'track/' + this.slug)
    },
    getTrackContents: async function(units) {
      let that = this
      let unitIds = units.map( unit => {
        return { "unitId": unit };
      });

      let table = `${this.ENV.tablePrefix}LearningUnits`
      let params = {
        RequestItems: {}
      }
      params.RequestItems[table] = { Keys: unitIds }
      this.trackContent = []

      let contents = await this.docClient.batchGet(params).promise()
      return contents
    },
    getTrack: async function() {
      let that = this
      let params = {
        TableName: `${this.ENV.tablePrefix}Tracks`,
        Key: { "slug": this.slug }
      }

      let units = await this.docClient.get(params).promise()
      return units
    },
    gotToTrack(targ){
      this.recordEvent('110', "target: /track/" + targ, 'track/' + this.slug)
      this.$router.push(`/track/${targ}`)
    },
    isActive(unitId, title){
      let classes = ""
      if(this.userProgress && this.userProgress.activeUnits){
        let userIsActive = this.userProgress.activeUnits.find(u => {
          return u === unitId
        })

        if(userIsActive) {
          classes += "active"
        } else if(this.expandedUnit == '' || this.expandedUnit == unitId){
            classes += "open"
            this.expandedUnit = unitId
        }
      }
      return classes
    },
    isUpdated(unit){
      let cssClass = ''
      let today = dayjs()
      if(today.diff(unit.createdAt, this.ENV.newFlagUnit) <= this.ENV.newFlagTime){
        cssClass = 'new'
      }
      else if(unit.unitMarkUpdated && today.diff(unit.unitUpdateDate, this.ENV.newFlagUnit) <= this.ENV.newFlagTime){
        cssClass = 'updated'
      }

      return cssClass
    },
    calcExpand(unitId){
      if(this.expandedUnit == unitId){
        return true
      }
    },
    setUnitCompletion(unitId, title){
      let that = this
      let uIndex = -1
      let units = []
      let tIndex = -1
      let tracks = []

      if(this.userProgress && this.userProgress.activeUnits){
        units = this.userProgress.activeUnits
        uIndex = units.indexOf(unitId)
      } else {
        uIndex = -1
      }

      if(this.userProgress && this.userProgress.activeTracks){
        tracks = this.userProgress.activeTracks
        tIndex = tracks.indexOf(this.slug)
      } else {
        tIndex = -1
      }

      if(uIndex > -1){
        units.splice(uIndex, 1)
      } else {
        units.push(unitId)
        if (this.user && this.user.attributes['custom:role']!='admin') this.recordEvent('120', title, this.slug)
      }

      if(tIndex < 0) {
        tracks.push(this.slug)
      }

      let params = {
        TableName: `${this.ENV.tablePrefix}Users`,
        Key: { "user": this.user.attributes.sub },
        UpdateExpression: 'set activeUnits = :activeUnits, lastTrack = :lastTrack, activeTracks = :activeTracks',
        ExpressionAttributeValues: { ":activeUnits": units, ":lastTrack": this.localTrack.slug, ":activeTracks": tracks }
      }

      this.docClient.update(params, function(err, data) {
          if (err) console.log(err)
          else AmplifyStore.dispatch('private/getUserProgress', that.user.attributes.sub)
      });
      // this.setLastTrack()
      this.expandedUnit = ''
    },
    recordEvent(eventName, detail, where) {
      let that = this
      let params = {
          TableName: `${this.ENV.tablePrefix}EventLog`,
          Item: {
              "time": dayjs().utc().format(),
              "event": eventName.toString(),
              "user": this.user.attributes.sub,
              "group": this.group.slug,
              "detail": detail,
              "where": where,
          },
      }

      this.docClient.put(params, function(err, data) {
          if (err) {
            console.log(err)
          } else {
            // console.log('Tracked event ' + eventName);
          }
      });

    },
    recordTrackView() {
      if (this.user && this.user.attributes['custom:role']!='admin'){

        this.recordEvent('100', this.slug)

        let params = {
          TableName: `${this.ENV.tablePrefix}Users`,
          Key: { "user": this.user.attributes.sub },
          UpdateExpression: 'set trackViews = list_append(if_not_exists(trackViews, :empty_list), :trackViews)',
          ExpressionAttributeValues: { ":trackViews": [{"track": this.slug, "when": dayjs().utc().format()}], ":empty_list": [] }
        }

        this.docClient.update(params, function(err, data) {
            if (err) console.log(err)
        });
      }
    },
    trackVideoEvent(ename, vid) {
      if (this.user && this.user.attributes['custom:role']!='admin'){
        this.recordEvent(ename, vid, 'track/' + this.slug)
      }
    },
    findTrackIcon(theIcon){
      let trackIcon = false
      if(this.allIcons) trackIcon = this.allIcons.find((i) => i.slug === theIcon)

      if(trackIcon) return trackIcon.svgfile
      else return '' 
    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    group() { return AmplifyStore.state.private.group },
    docClient() { return AmplifyStore.state.docClient },
    tracks() { return AmplifyStore.state.private.allTracks },
    topics() { return AmplifyStore.state.private.topics },
    allIcons() { return AmplifyStore.state.admin.allIcons },
    userProgress() { return AmplifyStore.state.private.userProgress },
    sortedUnits() {
      let sortedUnits = this.localTrack.units.map(u => {
        return this.trackContent.find(t => {
          return t.unitId === u
        })
      })

      let filterdUnits = sortedUnits.filter(u => {
        return !u.unitDisabled
      })

      return filterdUnits
    },
    trackProgress(){
      let completedUnits = this.localTrack.units.filter(u => {
        return this.userProgress.activeUnits.includes(u);
      })
      return completedUnits
    },
    parentTopic(){
      if(this.topics){
        let theParent = null
        this.topics.forEach(thisTopic => {
          if(!theParent){
            let match = thisTopic.tracks.find(tr => tr === this.localTrack.slug)
            if (match) {
              theParent = thisTopic
            }
          }
        });
        return theParent;
      }
    },
    cssVars() {
      if(this.parentTopic){
        return {
          '--track-primary': this.parentTopic.primaryColor,
          '--track-secondary': this.parentTopic.secondaryColor
        }
      } else {
        return
      }
    },
    FindNextUp(){
      let nextUpTrack = false
      if(this.tracks) nextUpTrack = this.tracks.find((t) => t.slug === this.localTrack.nextUp)
        
      return nextUpTrack
    }  
  },
  watch: {
    '$route' (to, from) {
      this.initTrack()
    },
    // 'group'(val) {
    //   this.trackView()
    // },
  }
})
</script>

<style lang="scss" scoped>
  .unitTop .unitRight,
  .unitTop .unitLeft{
    pointer-events: none;
  }
  .headerBorder {
    background-color: var(--track-primary);
  }
</style>
