<template>
  <div class="bodySpacer verticalSpacer admin display-flex" v-bind:class="trackDisabled ? 'hidden-track' : ''">

    <div id="adminHead">
      <h2>
        <span v-if="!slug">Create New Track</span>
        <span v-else><strong>Edit Track:</strong> {{trackTitle}}</span>
      </h2>
      <div class="inputWrap flex-third">
              <div class="toggle">
                <button @click="toggleTrackDisplay('active')" v-if="trackDisabled">Activate</button>
                <button @click="toggleTrackDisplay('disabled')" v-if="!trackDisabled">Deactivate</button>
              </div>
      </div>
    </div>
    <section id="createLeft">
      <div class="createLeftHeader" :class="{'update': trackMarkUpdated}">
        <h6>Track Details</h6>
        <div class="inputWrap" v-if="!slug">
          <label for="">Track Title</label>
          <input type="text" v-model="trackTitle" placeholder="Try to make it engaging" v-bind:class="titleDuplicated()"/>
        </div>
        <div class="actions" v-if="slug">
          <span v-if="trackMarkUpdated">Updated within {{ ENV.newFlagTime }} {{ ENV.newFlagUnit }} &nbsp;&nbsp;</span>
          <button @click="toggleUpdateFlag()">
            <span v-if="trackMarkUpdated">Remove Flag</span>
            <span v-if="!trackMarkUpdated">Mark Updated</span>
          </button>
        </div>
      </div>

      <div class="flex-wrap">
        <div class="flex-threeQuarter">
          <div class="inputWrap">
          <label for="">Track Description</label>
          <textarea v-model="trackIntro" :placeholder="'What is this track about?'"></textarea>
        </div>
          <div class="inputWrap">
            <label for="audience">Who's it for?</label>
            <select name="audience" v-model="trackAudience">
              <option disabled value="">Please select one</option>
              <option>General Money Topic</option>
              <option>For Adults</option>
              <option>For Couples</option>
              <option>Young Adults (18-34)</option>
              <option>Parents with Kids at Home</option>
              <option>Parents with Kids under 12</option>
              <option>Parents of Teens</option>
              <option>Boomers and up</option>
              <option>High Net Worth</option>
            </select>
          </div>
          <div class="inputWrap">
            <label for="watchNext">Watch Next</label>
            <select name="watchNext" v-model="watchNext">
              <option value="">None / Default</option>
              <option v-for="track in sortedTracks" :value="track.slug">{{ track.title }}</option>
            </select>
          </div>
        </div>
              
        <div class="inputWrap flex-quarter">
          <label for="icon">Display Icon</label>
          <VDropdown>
            <div class="trackIcon">
              <div class="svgIcon" v-if="trackIcon && trackIcon != '' && findTrackIcon" v-html="findTrackIcon"></div>
              <div class="noIcon" v-else>?</div>
            </div>

            <template #popper>
              <div class="icon-list">
                <div class="icon-block" 
                  v-for="icon in allIcons" :key="icon.slug" 
                  :class="icon.slug === trackIcon ? 'active' : ''"
                  @click="selectIcon(icon.slug)"
                  v-close-popper>
                  <div v-html="icon.svgfile" class="icon-wrapper"></div>
                </div>
              </div>
            </template>
          </VDropdown>

        </div>
      </div>

      <h6>Tag List</h6>
      <vue3-tags-input :tags="trackTags"
          class="tagList"
          placeholder="enter keywords"
          :add-tag-on-keys="[13,188]"
          @on-tags-changed="updateTags" />

      <h6>Available Units</h6>
      <div class="inputWrap">
        <input name="query" type="text" placeholder="Find Learning Unit" v-model="searchQuery">
        <span class="clearSearch" v-if="searchQuery" @click="clearFilter">Show all</span>
      </div>
      <AddUnits v-if="allLearningUnits" @addUnit="addUnitToTrackUnits" :filterKey="searchQuery" :allLearningUnits="allLearningUnits" :trackUnits="trackUnits" />

      <div class="buttonWrap">
        <div class="button" v-bind:data-btnText="slug ? 'Update Track' : 'Create Track'" @click="createTrack()" data-btnTextHover="Save">&nbsp;</div>
      </div>

    </section>
    <div id="preview">
      <h6>Track Preview</h6>
      <div class="createPreview trackPreview">
        <div id="trackHeader" class="verticalSpacer">
          <div class="bodySpacer">
            <h2>{{trackTitle}}</h2>
          </div>
        </div>
        <div class="bodySpacer verticalSpacer">
          <div id="trackInfo">
            <div class="trackOverview" v-html="trackIntro"></div>
          </div>
        </div>

          <draggable id="unitList" v-model="trackUnits">
            <transition-group name="sortList">
              <!-- Add class "open" to the expanded state -->
              <div class="unitWrap sortList-item" v-for="unit in trackUnits" v-bind:key="unit.unitId">
                <div class="remove" @click="removeUnit(unit)"></div>
                <div class="unit">
                  <div class="unitTop">
                    <div class="unitLeft">
                      <h5>{{unit.type}}</h5>
                      <h3>{{unit.title}}</h3>
                    </div>
                    <div class="unitRight"></div>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>

      </div>
    </div>
</template>

<script>
import uuid from 'uuid'
import slugify from 'slugify'
import AddUnits from './CreateTrack.AddUnits'
import AmplifyStore from '@/store/store'
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import Vue3TagsInput from 'vue3-tags-input';
import { Tooltip } from "floating-vue";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default defineComponent({
  name: 'CreateTrack',
  props: ['slug'],
  components: { AddUnits, Vue3TagsInput, draggable: VueDraggableNext },
  data () {
    return {
      searchQuery: '',
      localTrack: false,
      trackTitle: '',
      trackIntro: '',
      trackAudience: '',
      trackIcon: false,
      trackUnits: [],
      trackId: false,
      trackDisabled: false,
      trackCreated: false,
      unitsUpdated: false,
      trackTags: [],
      trackMarkUpdated: false,
      trackUpdateDate: null,
      watchNext: ''
    }
  },
  created () {
    AmplifyStore.dispatch('admin/getAllLearningUnits')
      .then(() => this.getTrack() )
  },
  mounted () {
    if(!this.allIcons) AmplifyStore.dispatch('admin/getAllIcons')
    if(!this.allTracks) AmplifyStore.dispatch('private/getAllTracks')
  },
  methods: {
    clearFilter: function(){
      this.searchQuery = ''
    },
    updateTags: function(tags){
      this.trackTags = tags.map(t => t.toLowerCase())
      // console.log(this.trackTags)
    },
    inTrack: function(unit) {
      let unitIds = this.trackUnits.map( u => u.unitId)
      if(unitIds.includes(unit.unitId)) return true
      return false
    },
    // moveUp: function(unit) {
    //   let index = this.getIndexOfUnit(unit.unitId)
    //   if(index > 0) this.setArrayPosition(index, (index - 1))
    // },
    // moveDown: function(unit) {
    //   let index = this.getIndexOfUnit(unit.unitId)
    //   if(index < (this.trackUnits.length - 1)) this.setArrayPosition(index, (index + 1))
    // },
    addUnitToTrackUnits: function(unit) {
      this.trackUnits.push(unit)
      this.unitsUpdated = true;
    },
    removeUnit: function(unit) {
      let index = this.getIndexOfUnit(unit.unitId)
      let units = this.trackUnits.map( u => u);
      units.splice(index, 1);
      this.trackUnits = units;
      this.unitsUpdated = true;
    },
    setArrayPosition: function(from, to) {
      let element = this.trackUnits[from];
      let units = this.trackUnits.map( u => u);
      units.splice(from, 1);
      units.splice(to, 0, element);
      this.trackUnits = units;
    },
    getIndexOfUnit: function(unitId) {
      return this.trackUnits.map( u => u.unitId).indexOf(unitId)
    },
    toggleTrackDisplay: function() {
      this.trackDisabled = !this.trackDisabled
    },
    getTrack: function() {
      let that = this
      let params = {
        TableName: `${this.ENV.tablePrefix}Tracks`,
        Key: { "slug": this.slug }
      }

      this.docClient.get(params, function(err, data) {
        if (err) {
          that.localTrack = false
        } else {
          that.localTrack = data.Item
          that.trackTitle = that.localTrack.title
          that.trackSlug = that.localTrack.slug
          that.trackIntro = that.localTrack.content
          that.trackId = that.localTrack.trackId
          that.trackDisabled = that.localTrack.trackDisabled || false
          that.trackCreated = that.localTrack.createdAt
          that.trackTags = that.localTrack.keywords
          that.trackUnits = that.localTrack.units.map(l => {
            return that.allLearningUnits.find(u => {
              return u.unitId === l
            })
          })
          that.trackAudience = that.localTrack.audience
          that.trackIcon = that.localTrack.iconSlug
          that.trackUpdateDate = that.localTrack.updateContentDate
          that.watchNext = that.localTrack.nextUp

          if(that.trackUpdateDate && dayjs().diff(that.trackUpdateDate, that.ENV.newFlagUnit) <= that.ENV.newFlagTime){
            that.trackMarkUpdated = true
          }
        }
      })
    },
    titleDuplicated: function() {
      if(this.allTracks){
        let matchingTitle = this.allTracks.filter(t => {
          return t.title.toLowerCase() === this.trackTitle.toLowerCase().trim()
        })[0]

        if(matchingTitle) return 'error'
      }
      return ''
    },
    setRemoveFoundInParams: function(unitId, slug) {
      let params = {
        TableName : `${this.ENV.tablePrefix}LearningUnits`,
        Key: {'unitId': unitId},
        UpdateExpression: 'delete #foundIn :val',
        ExpressionAttributeNames : {
          '#foundIn' : 'foundIn'
        },
        ExpressionAttributeValues: {
          ":val": this.docClient.createSet([slug])
        }
      };

      this.updateUnitFoundIn(params)
    },
    setAddFoundInParams: function(unitId, slug) {
      let params = {
        TableName : `${this.ENV.tablePrefix}LearningUnits`,
        Key: {'unitId': unitId},
        UpdateExpression: 'ADD #foundIn :val',
        ExpressionAttributeNames : {
            '#foundIn' : 'foundIn'
          },
        ExpressionAttributeValues: {
          ":val": this.docClient.createSet([slug])
        }
      };

      this.updateUnitFoundIn(params)
    },
    updateUnitFoundIn: function(params) {
      this.docClient.update(params, function(err, data) {
        if(err) console.log(err)
        // else console.log(data)
      });
    },
    createTrack: function() {
      let that = this
      let unitIds = this.trackUnits.map(t => {
        return t.unitId
      })

      let params = {
        "Item": {
          "units": unitIds,
          "title": this.trackTitle,
          "content": this.trackIntro,
          "trackId": this.trackId || uuid.v1(),
          "slug": this.trackSlug || slugify(this.trackTitle.toLowerCase()),
          "createdAt": this.trackCreated || dayjs().utc().format(),
          "audience": this.trackAudience,
          "iconSlug": this.trackIcon,
          "trackDisabled": this.trackDisabled,
          "keywords": this.trackTags,
          "nextUp": this.watchNext,
        }
      }

      let removeUnits = [];
      let addUnits = [];

      if(this.localTrack.units) removeUnits = this.localTrack.units.filter( u =>  !unitIds.includes(u) )
      if(this.localTrack.units) addUnits = unitIds.filter( u => !this.localTrack.units.includes(u) )

      for (let i=0; i < removeUnits.length; i++) {
        this.setRemoveFoundInParams(removeUnits[i], params.Item.slug)
      }

      for (let i=0; i < unitIds.length; i++) {
        this.setAddFoundInParams(unitIds[i], params.Item.slug)
      }
      if(this.unitsUpdated || this.trackMarkUpdated){
        params.Item.updateContentDate = dayjs().utc().format()
      }

      this.docClient.put({ TableName : `${this.ENV.tablePrefix}Tracks`, Item: params.Item }, function(err, data) {
        if(err) {
          //console.log(err)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'warn',
            header: 'Update Failed',
            message: 'Track: ' + that.trackTitle + '. Please try again.'
          })
        } else {
          console.log(data)
          AmplifyStore.commit('set_flashMessage', {
            show: true,
            level: 'success',
            header: 'Updates Saved!',
            message: 'Track: ' + that.trackTitle
          })
        }
      });

    },
    selectIcon: function(slug) {
      if(slug != 'blank') {
        this.trackIcon = slug
      }
      if(slug === 'blank') {
        this.trackIcon = false
      }
    },
    toggleUpdateFlag: function(){
      this.trackMarkUpdated = !this.trackMarkUpdated
    }
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    docClient() { return AmplifyStore.state.docClient },
    allLearningUnits() { return AmplifyStore.state.admin.allLearningUnits },
    allIcons() { return AmplifyStore.state.admin.allIcons },
    allTracks() { return AmplifyStore.state.private.allTracks },
    findTrackIcon(){
      let theIcon = false
      if(this.allIcons && this.trackIcon) theIcon = this.allIcons.find((i) => i.slug === this.trackIcon)
      
      if(theIcon) return theIcon.svgfile
      else return 
    },
    // isUpdated() {
    //   if(this.trackUpdateDate && dayjs().diff(this.trackUpdateDate, this.ENV.newFlagUnit) <= this.ENV.newFlagTime){
    //     return true;
    //   }
    //   return false;
    // }, 
    sortedTracks() {
      if(this.allTracks && this.localTrack){
        const filteredTracks = this.allTracks.filter((t) => t.slug != this.localTrack.slug && t.title)
        return filteredTracks.sort(function(a,b) {
          var textA = a.title;
          var textB = b.title;
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
      } 
    }
  }
})
</script>

<style lang="scss" scoped>

  .error{
    border: 1px solid red;
    background-color: red
  }

  .tagList {
    margin-bottom: 2rem;
  }

  .trackIcon {
    padding: 1rem;
    border: 2px solid transparent;
    border-radius: 5px;
    max-width: 7rem;
    margin: 0 auto;
    transition: all 0.25s ease;
    background-color: var(--color-white);
    &:hover {
      border-color: var(--track-primary);
      cursor: pointer;
    }
  }

  .icon-list {
    padding: 1rem;
    width: 500px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
    max-height: 400px;
    overflow-y: auto;
  }

  .icon-block {
    padding: 0.5rem;
    border: 1px solid var(--color-gray3);
    border-radius: 3px;
    transition: all 0.25s ease;
    :deep(svg) {
      fill: var(--color-gray5);
    }

    &:not(.active):hover {
      cursor: pointer;
      :deep(svg) {
        fill: var(--track-primary);
      }
    }

    &.active {
      background-color: var(--track-primary);
      pointer-events: none;
      :deep(svg) {
        fill: var(--color-white);
      }
    }
  }

  .noIcon {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin: 1rem;
  }
 
</style>
