<template>
  <div>
    <div class="headerBorder"></div>
    <div id="allTracks" v-if="this.userProgress" class="bodySpacer verticalSpacer">
      <section id="trackList" v-if="enabledTracks" class="grid"
        :class="[settings.featureTrack == 'yes' && !showSearchResults ? 'hasFeatured': '', 
                user.attributes['custom:groupSlug'] === 'demo' ? 'isDemo' : ''
                ]"
        >
        <div class="trackListHeader" v-if="enabledTracks">
          <span v-if="!showSearchResults">All Learning Tracks ({{ enabledTracks.length }})</span>
          <span class="info" v-if="showSearchResults">Results For "{{ searchValue }}" ({{enabledTracks.length}}) <a href="#" @click.prevent="clearSearch">Clear Results</a></span>
          <div class="search-box">
            <input type="search" placeholder="Find by keyword" v-model="searchInput" @keyup.enter="doSearch">
            <button @click="doSearch">Search</button>
          </div>
        </div>
        <transition-group name="tracktiles">
          <TrackTile v-for="track in enabledTracks" :track="track" :key="track.slug" />
          <div class="noResults" v-if="!enabledTracks || enabledTracks.length < 1">
            <div>
              <h2>We couldn't find any matches.</h2>
              <p>Tips for improving results:</p>
              <ul>
                <li>Double check your spelling</li>
                <li>Search for a less specific term to broaden your search</li>
                <li>Try single words vs. phrases</li>
                <li>Consider using a synonym or related terms to expand your search</li>
                <li>Visit the <router-link to="/AllTopics">Topic list</router-link> for money categories</li>
              </ul>
              <div class="actions">
                <div  @click="clearSearch" class="viewTrack button" data-btnText="Clear Search" data-btnTextHover="Clear Search">&nbsp;</div>
              </div>
            </div>
          </div>
        </transition-group>
      </section>
    </div>
  </div>
</template>

<script>
import AmplifyStore from '../store/store'
import TrackTile from '@/components/TrackTile'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default {
  name: 'TrackList',
  components: { TrackTile },
  data () {
    return {
      lastTrack: false,
      displayGrid: true,
      searchValue: '',
      searchInput: '',
      showSearchResults: false,
    }
  },
  mounted () {
    if(!this.tracks) AmplifyStore.dispatch('private/getAllTracks')
    if(!this.userProgress) AmplifyStore.dispatch('private/getUserProgress', this.user.attributes.sub)
  },
  methods: {
    toggleDisplayType(){
      if(this.displayGrid){
        this.displayGrid = false;
      } else {
        this.displayGrid = true;
      }
    },
    getTopic(slug){
      this.$router.push(`/topic/${slug}`)
    },
    doSearch(){
      if(this.searchInput != '') {
        this.showSearchResults = true
        this.searchValue = this.searchInput
      }
      if(this.searchInput == '' && this.showSearchResults) this.clearSearch()
    },
    clearSearch(){
        this.showSearchResults = false
        this.searchValue = ''
        this.searchInput = ''
    },
    recordEvent(eventName) {
      let that = this
      let params = {
          TableName: `${this.ENV.tablePrefix}EventLog`,
          Item: {
              "time": dayjs().utc().format(),
              "event": eventName,
              "user": this.user.attributes.sub,
              "group": this.group.slug,
              "detail": this.searchValue,
          },
      }

      this.docClient.put(params, function(err, data) {
          if (err) {
            console.log(err)
          } 
      });

    },
  },
  computed: {
    ENV() { return AmplifyStore.state.ENV },
    user() { return AmplifyStore.state.user },
    group() { return AmplifyStore.state.private.group },
    docClient() { return AmplifyStore.state.docClient },
    tracks() { return AmplifyStore.state.private.allTracks },
    trackDisplay() { return AmplifyStore.state.private.trackDisplay },
    topics() { return AmplifyStore.state.private.topics },
    userProgress() { return AmplifyStore.state.private.userProgress },
    settings() { return AmplifyStore.state.admin.settings },
    enabledTracks() {
      this.showSearchResults // force refresh
      if(this.tracks && this.trackDisplay){
        let displayTracks = null
        // find all tracks
        let orderedTracks = this.trackDisplay.map( s => {
          return this.tracks.find( t => t.slug === s)
        });
        // if no search just show tracks
        if(!this.showSearchResults) displayTracks = orderedTracks

        if(this.showSearchResults){
          let searchTerm = this.searchValue.toLowerCase();
          displayTracks = orderedTracks.filter( t => {
           if(t.title.toLowerCase().includes(searchTerm)) return true
           if(t.keywords && t.keywords.some(k => k.includes(searchTerm))) return true
          })
          
          if(displayTracks.length == 0 && this.user.attributes['custom:role']!='admin') {
            this.recordEvent('410');
          }

          if(displayTracks.length > 0 && this.user.attributes['custom:role']!='admin') {
            this.recordEvent('400');
          }
        }
        // no disabled tracks
        return displayTracks.filter( t => !t.trackDisabled )
      }
    }
  }
}
</script>

<style scoped>
</style>
